<template>
  <div>
    <div class="title">定向采购管理</div>
    <div class="form">
      <div class="block">
        <div class="label">合同日期<span style="color: red;">*</span></div>
        <el-date-picker v-model="form.date" type="date" placeholder="选择日期" size="small" value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>
      <div class="hr"></div>

      <div class="block">
        <div class="label">订单类型<span style="color: red;">*</span></div>
        <el-radio-group v-model="form.radio">
          <el-radio v-for="(item, index) in radioList" :key="index" :label="item.code">{{ item.value }}</el-radio>
        </el-radio-group>
      </div>
      <div class="hr"></div>

      <div class="block">
        <div class="label">卖方公司<span style="color: red;">*</span></div>
        <div class="content">
          (输出对方公司关键字，点击搜索后选择对方交易员)
        </div>
      </div>
      <div class="hr"></div>

      <div class="block-1">
        <div class="content-block">
          <div class="content-block__label">卖方公司名：</div>
          <div class="content-block__content">
            <el-input v-model="sellerCompanyName" style="width:350px;" size="small" placeholder="输入卖方公司关键字"></el-input>
            <!-- <div class="btn" @click="searchCompanies">搜索</div> -->
          </div>
        </div>

        <div style="margin-left: 84px;position: absolute;z-index: 99;border: 1px solid #dcdcdc;background: white; width: 350px;max-height: 180px;overflow-y: auto;" v-if="options.length > 0">
          <ul style="list-style-type: none;cursor:pointer;">
            <li v-for="(item, index) in options" :key="index">
              <div style="padding: 10px 10px 0 10px; box-sizing: border-box;width: 100%;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;color: #606266;" @click="handleSelectBuyerCompany(item)">
                {{ item.companyName }}
              </div>
            </li>
          </ul>
        </div>

        <div style="margin-left: 84px;">
          <ul style="list-style-type: none;cursor:pointer;">
            <li v-for="(item, index) in sellerCompanyList" :key="index">
              【<span style="color:red; line-height: 22px;">{{
                item.sellerCompanyName
              }}</span>
              <el-radio-group v-model="companyRadio" @change="chooseCompany(item)" style="margin-left: 20px;margin-top: 10px;">
                <div style="display:flex;flex-wrap:wrap;">
                  <div style="width: 180px; height:24px;" v-for="(item, index) in item.users" :key="index">
                    <el-radio :label="item.userId">{{ item.name }}</el-radio>
                  </div>
                </div>
              </el-radio-group>】
            </li>
          </ul>
        </div>
      </div>

      <div class="block" style="margin-top: 50px;">
        <div class="label">合同编号<span style="color: red;">*</span></div>
        <div class="content">(请填写合同编号)</div>
      </div>
      <div class="hr"></div>

      <div class="block">
        <div class="label"></div>
        <div class="content-block">
          <div class="content-block__label">合同编号：</div>
          <div class="content-block__content">
            <el-input style="width:350px;" size="small" v-model="contractNumber" @input="checkNum" maxlength="50"></el-input>
          </div>
        </div>
      </div>
      <div class="block" style="margin-top: 50px;">
        <div class="label">合同明细<span style="color: red;">*</span></div>
        <div class="add-item-btn" @click="addItem">
          {{ tableData.length == 0 ? "新增商品" : "添加商品" }}
        </div>
      </div>
      <div class="hr"></div>

      <div class="table">
        <el-table :data="tableData" :header-cell-style="{
            'text-align': 'center',
            'font-weight': 'normal',
            'font-size': '12px',
            color: '#333'
          }" :cell-style="{
            'text-align': 'center',
            'font-weight': 'normal',
            'font-size': '12px',
            color: '#333'
          }" border style="width: 100%" size="mini">
          <el-table-column prop="categoryName" label="品种" width="260">
          </el-table-column>
          <el-table-column prop="commodityName" label="品名" width="180">
          </el-table-column>
          <el-table-column prop="productorName" label="品牌"> </el-table-column>
          <el-table-column prop="warehouseName" label="交货仓库">
          </el-table-column>
          <el-table-column prop="weight" label="重量"> </el-table-column>
          <el-table-column prop="weightUnit" label="重量单位">
          </el-table-column>
          <el-table-column prop="price" label="价格"> </el-table-column>
          <el-table-column prop="priceUnit" label="价格单位"> </el-table-column>
          <el-table-column prop="amountOfMoney" label="金额(元)">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button @click="deleteItem(scope.$index, tableData)" type="text" size="small" v-show="scope.row.categoryName !== '合计'">删除</el-button>
              <el-button type="text" size="small" @click="updateItem(scope.row)" v-show="scope.row.categoryName !== '合计'">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="block" style="margin-top: 50px;">
        <div class="label">合同模板<span style="color: red;">*</span></div>
        <div class="content-block">
          <span v-show="isShowTip">未找到商品对应合同模板，请联系客服人员核实并维护缺失的模板信息</span>
          <div v-for="(item, index) in templateNameList" :key="index" style="margin-left:20px;">
            <input type="radio" @click="chooseTemplate(item.contractTemplateId)" />
            <span class="radio-item" @click="gotoContractTemp(item.contractTemplateId)">{{ item.contractTemplateName }}</span>
          </div>
        </div>
      </div>
      <div class="hr"></div>

      <div class="send-btn" @click="sendToBuyer">发送给卖方</div>
    </div>

    <el-dialog title="添加商品" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="35%">
      <div slot="title">{{isEdit ? '编辑商品' : '添加商品'}}</div>
      <div @click="hideContainer">
        <el-form :model="itemForm" ref="itemForm" :rules="rules" style="width: 450px;margin: 0 auto;" size="small">
          <el-form-item label="品种：" :label-width="formLabelWidth" prop="categoryName">
            <el-input v-model="itemForm.categoryName" @focus="showCategory" @input="searchCategory" id="category" style="width: 260px"></el-input>

            <div class="container" v-if="showCategoryContainer">
              <div class="head">请选择品种或输入品种的拼音</div>
              <div class="content">
                <div v-for="(item, index) in cateContent" :key="index" class="content-item" :title="item.categoryName" @click="handleSelectSCategory(item)">
                  {{ item.categoryName }}
                </div>
              </div>
              <div class="footer" id="cateFooter">
                <div class="common" @mouseover="showAllCategory">常用</div>
                <div class="key-group">
                  <div v-for="(item, index) in categoryContent" :key="index" class="key" @mouseover="hoverCategory(item.value)">
                    {{ item.key }}
                  </div>
                </div>
              </div>
            </div>

            <div class="list" v-if="showCategorylist">
              <ul style="list-style-type: none;cursor:pointer;">
                <div style="border-bottom: 1px dashed #dcdcdc; color: #656565; font-size: 12px; padding-left: 10px;">
                  输入中文，进行排序
                </div>
                <li v-for="(item, index) in categorySearchResult" :key="index" @click="handleSelectSCategory(item)">
                  {{ item.categoryName }}
                </li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item label="品名：" :label-width="formLabelWidth" prop="commodityName">
            <el-input v-model="itemForm.commodityName" @focus="showCommodity" @input="searchCommodity" id="commodity" style="width: 260px"></el-input>

            <div class="container" v-if="showCommodityContainer">
              <div class="head">请选择品名或输入品名的拼音</div>
              <div class="content">
                <div v-for="(item, index) in comContent" :key="index" class="content-item" :title="item.commodityName" @click="handleSelectCategoryName(item)">
                  {{ item.commodityName }}
                </div>
              </div>
              <div class="footer" id="comFooter">
                <div class="common" @mouseover="showAllCommodity">常用</div>
                <div class="key-group">
                  <div v-for="(item, index) in commodityContent" :key="index" class="key" @mouseover="hoverCommodity(item.value)">
                    {{ item.key }}
                  </div>
                </div>
              </div>
            </div>

            <div class="list" v-if="showCommoditylist">
              <ul style="list-style-type: none;cursor:pointer;">
                <div style="border-bottom: 1px dashed #dcdcdc; color: #656565; font-size: 12px; padding-left: 10px;">
                  输入中文，进行排序
                </div>
                <li v-for="(item, index) in commoditySearchResult" :key="index" @click="handleSelectCategoryName(item)">
                  {{ item.commodityName }}
                </li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item label="品牌：" :label-width="formLabelWidth" prop="productorName">
            <el-input v-model="itemForm.productorName" @focus="showProductor" @input="searchProductor" id="productor" style="width: 260px"></el-input>

            <div class="container" v-if="showProductorContainer">
              <div class="head">请选择品牌或输入品牌的拼音</div>
              <div class="content">
                <div v-for="(item, index) in proContent" :key="index" class="content-item" :title="item.productorName" @click="handleSelectProducer(item)">
                  {{ item.productorName }}
                </div>
              </div>
              <div class="footer" id="proFooter">
                <div class="common" @mouseover="showAllProductor">常用</div>
                <div class="key-group">
                  <div v-for="(item, index) in productorContent" :key="index" class="key" @mouseover="hoverProductor(item.value)">
                    {{ item.key }}
                  </div>
                </div>
              </div>
            </div>

            <div class="list" v-if="showProductorlist">
              <ul style="list-style-type: none;cursor:pointer;">
                <div style="border-bottom: 1px dashed #dcdcdc; color: #656565; font-size: 12px; padding-left: 10px;">
                  输入中文，进行排序
                </div>
                <li v-for="(item, index) in productorSearchResult" :key="index" @click="handleSelectProducer(item)">
                  {{ item.productorName }}
                </li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item label="交货仓库：" :label-width="formLabelWidth" prop="warehouseName">
            <el-input v-model="itemForm.warehouseName" @focus="showWarehouse" @input="searchWarehouse" id="warehouse" style="width: 260px"></el-input>

            <div class="container" v-if="showWarehouseContainer">
              <div class="head">请选择交货仓库或输入交货仓库的拼音</div>
              <div class="content">
                <div v-for="(item, index) in wareContent" :key="index" class="content-item" :title="item.warehouseName" @click="handleSelectWarehouse(item)">
                  {{ item.warehouseName }}
                </div>
              </div>
              <div class="footer" id="wareFooter">
                <div class="common" @mouseover="showAllWarehouse">常用</div>
                <div class="key-group">
                  <div v-for="(item, index) in warehouseContent" :key="index" class="key" @mouseover="hoverWarehouse(item.value)">
                    {{ item.key }}
                  </div>
                </div>
              </div>
            </div>

            <div class="list" v-if="showWarehouselist">
              <ul style="list-style-type: none;cursor:pointer;">
                <div style="border-bottom: 1px dashed #dcdcdc; color: #656565; font-size: 12px; padding-left: 10px;">
                  输入中文，进行排序
                </div>
                <li v-for="(item, index) in warehouseSearchResult" :key="index" @click="handleSelectWarehouse(item)">
                  {{ item.warehouseName }}
                </li>
              </ul>
            </div>
          </el-form-item>
          <el-form-item label="重量：" :label-width="formLabelWidth" prop="weight">
            <el-input v-model="itemForm.weight" @input="
                itemForm.weight = itemForm.weight.replace(
                  /[^\d+(\.{0,1}\d+){0,1}]/g,
                  ''
                )
              " autocomplete="off" style="width: 260px"></el-input>
            <span>{{ unit }}</span>
          </el-form-item>
          <el-form-item label="单价：" :label-width="formLabelWidth" prop="price">
            <el-input v-model="itemForm.price" @input="
                itemForm.price = itemForm.price.replace(
                  /[^\d+(\.{0,1}\d+){0,1}]/g,
                  ''
                )
              " autocomplete="off" style="width: 260px"></el-input>
            <span>元/{{ unit }}</span>
          </el-form-item>
          <el-form-item label="金额（元）：" :label-width="formLabelWidth">
            <el-input v-model="amountOfMoney" disabled style="width: 260px"></el-input>
          </el-form-item>
          <el-form-item>
            <div class="add-dialog-footer">
              <div class="btn go-on" @click="goonAdd('itemForm')" v-if="!isEdit">继续添加</div>
              <div class="btn confirm" @click="submitItems('itemForm')">
                完 成
              </div>
              <div class="btn cancel" @click="dialogFormVisible = false">
                取 消
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getNowFormatDate } from "../../../utils/formatTime";
export default {
  name: "directSelling",
  data() {
    return {
      form: {
        date: getNowFormatDate(new Date()),
        radio: "0"
      },
      itemForm: {
        id: "",
        categoryName: "",
        commodityName: "",
        productorName: "",
        warehouseName: "",
        weight: "",
        price: ""
      },
      formLabelWidth: "100px",
      rules: {
        categoryName: [
          { required: true, message: "请输入品种", trigger: "change" }
        ],
        commodityName: [
          { required: true, message: "请输入品名", trigger: "change" }
        ],
        productorName: [
          { required: true, message: "请输入品牌", trigger: "change" }
        ],
        warehouseName: [
          { required: true, message: "请输入交货仓库", trigger: "change" }
        ],
        weight: [{ required: true, message: "请输入重量", trigger: "change" }],
        price: [{ required: true, message: "请输入单价", trigger: "change" }]
      },

      tableData: [
        {
          categoryName: "合计",
          amountOfMoney: 0.0
        }
      ],

      dialogFormVisible: false,

      sellerCompanyId: "",
      sellerCompanyName: "",
      sellerCompanyList: [],
      sellerOperatorId: "",
      sellerOperatorName: "",

      contractNumber: "",

      searchBtnId: "",
      sendBtnId: "",
      radioList: [],
      url: {},

      currentCategoryId: "",
      currentCommodityId: "",
      currentProductorId: "1",
      currentWarehouseId: "",

      templateNameList: [],
      contractTemplateId: "",
      contractTemplateName: "",

      unit: "",

      companyRadio: "",
      options: [],

      isEdit: false,

      isShowTip: false,

      hasError: false,

      showCategoryContainer: false,
      showCommodityContainer: false,
      showProductorContainer: false,
      showWarehouseContainer: false,

      showCategorylist: false,
      showCommoditylist: false,
      showProductorlist: false,
      showWarehouselist: false,

      categoryContent: [],
      cateContent: [],
      allCategory: [],
      categorySearchResult: [],

      commodityContent: [],
      comContent: [],
      allCommodity: [],
      commoditySearchResult: [],

      productorContent: [],
      proContent: [],
      allProductor: [],
      productorSearchResult: [],

      warehouseContent: [],
      wareContent: [],
      allWarehouse: [],
      warehouseSearchResult: []
    };
  },
  computed: {
    amountOfMoney() {
      return (+(this.itemForm.weight * this.itemForm.price)).toFixed(2);
    },
    userInfo() {
      return this.$store.state.UserInfo;
    }
  },
  created() {
    this.getDirectSellingInfo();
    //获取订单类型
    this.getTypes();
  },
  watch: {
    sellerCompanyName: function (val) {
      if (!val) {
        this.sellerCompanyList = [];
        return;
      }
      if (val.indexOf("【") > -1) {
        this.options = [];
        return;
      }
      this.querySearchSellerCompany();
    }
  },
  methods: {
    getTypes() {
      let params = {};
      this.$post("355cc1ed-6673-a8fe-737a-7b915fca6ff8", params)
        .then(res => {
          const { data } = res;
          this.radioList = data.orderTypes;
        })
        .catch(err => { });
    },
    getDirectSellingInfo() {
      let params = {
        process_id: "00000213", //流程ID
        nodeInfo: {} //传参  查询则是查询条件  可传可不传等
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then(result => {
          const { data } = result;
          this.searchBtnId = data.button.NodeId_1;
          this.sendBtnId = data.button.NodeId_2;
          this.url = data.url;
        })
        .catch(err => { });
    },
    searchCompanies() {
      if (this.sellerCompanyName == "") {
        this.$alert("卖方公司名不能为空！");
        return;
      }
      let params = {
        process_id: "00000213",
        execution_type: "get", //get 代表读请求 获取数据,  set	代表写请求 编辑等操作
        nextProcessNode: this.searchBtnId, // 上面接口的  button.NodeId_1字段的值
        nodeInfo: {
          companyName: this.sellerCompanyName.split("【")[0], //公司名称
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0 //分页页码（从0开始）
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then(result => {
          const { data } = result;
          this.sellerCompanyList = [];
          data.content.forEach(item => {
            if (this.sellerCompanyName.split("【")[0] == item.companyName) {
              this.sellerCompanyList.push({
                sellerCompanyId: item.companyId,
                sellerCompanyName: item.companyName,
                users: item.users
              });
            }
          });
          let users = this.sellerCompanyList[0]["users"];
          this.sellerCompanyName = `${this.sellerCompanyList[0]["sellerCompanyName"]
            }【${users[users.length - 1].name}】`;
          this.sellerCompanyId = `${this.sellerCompanyList[0]["sellerCompanyId"]}`;
          this.companyRadio = users[users.length - 1].userId;

          this.sellerOperatorId = users[users.length - 1].userId;
          this.sellerOperatorName = users[users.length - 1].name;
        })
        .catch(err => { });
    },
    chooseCompany(data) {
      let user = "";
      this.sellerCompanyId = data.sellerCompanyId;
      data.users.forEach(item => {
        if (item.userId == this.companyRadio) {
          user = item.name;
        }
      });
      this.sellerCompanyName = `${data.sellerCompanyName}【${user}】`;
      this.sellerOperatorName = user;
      this.sellerOperatorId = this.companyRadio;
    },
    addItem() {
      this.isEdit = false;
      this.dialogFormVisible = true;
      (this.currentCategoryId = ""),
        (this.currentCommodityId = ""),
        (this.currentProductorId = ""),
        (this.currentWarehouseId = "");
      this.$nextTick(() => {
        this.$refs.itemForm.resetFields();
        this.showCategoryContainer = false;
        this.showCommodityContainer = false;
        this.showProductorContainer = false;
        this.showWarehouseContainer = false;
        this.showCategorylist = false;
        this.showCommoditylist = false;
        this.showProductorlist = false;
        this.showWarehouselist = false;
      });
      Object.keys(this.itemForm).forEach(item => {
        this.itemForm[item] = "";
      });
      //品类搜索
      this.querySearchSCategory();
      this.querySearchSCategory("classifiedByPinyin");
      //仓库搜索
      this.querySearchSWarehouse();
      this.querySearchSWarehouse("classifiedByPinyin");
    },
    chooseTemplate(data) {
      this.templateNameList.forEach(item => {
        if (item.contractTemplateId == data) {
          this.contractTemplateName = item.contractTemplateName;
          this.contractTemplateId = data;
        }
      });
    },
    sendToBuyer() {
      if (!this.form.date) {
        this.$alert("合同日期不能为空");
        return;
      } else if (!this.sellerCompanyName) {
        this.$alert("卖方公司名不能为空");
        return;
      } else if (!this.contractNumber) {
        this.$alert("合同编号不能为空");
        return;
      } else if (this.tableData.length == 0) {
        this.$alert("请添加商品");
        return;
      } else if (!this.contractTemplateId) {
        this.$alert("请选择合同模板");
        return;
      } else if (!this.sellerOperatorId) {
        this.$alert("请选择卖方操作员");
        return;
      }

      let contractDetails = [];
      this.tableData.forEach(item => {
        if (item.id) {
          contractDetails.push(item);
        }
      });
      let totalWeight = 0;
      contractDetails.forEach(item => {
        if (item.weightUnit == '千克') {
          totalWeight += (+item.weight) / 1000;
        } else {
          totalWeight += +item.weight;
        }
      });

      let buyerContractInfo = {
        totalWeight: totalWeight,
        totalAmount: this.totalAmount,
        goodsData: contractDetails,
        contractDate: this.form.date,
        orderType: this.form.radio,
        sellerCompanyId: this.sellerCompanyId, //卖家公司id（定向采购时传该参数）
        sellerCompanyName: this.sellerCompanyNameTemp, //卖家公司名称（定向采购时传该参数）
        sellerOperatorId: this.sellerOperatorId,
        sellerOperatorName: this.sellerOperatorName,
        contractNumber: this.contractNumber,
        contractTemplateId: this.contractTemplateId, //合同模板id（定向销售和定向采购时传该参数）
        contractTemplateName: this.contractTemplateName //合同模板名称（定向销售和定向采购时传该参数）
      };
      sessionStorage.setItem(
        "buyerContractInfo",
        JSON.stringify(buyerContractInfo)
      );
      this.$router.push({
        path: "/contract",
        query: { tag: "buyer", id: this.contractTemplateId }
      });
    },
    submitItems(formName, tag) {
      this.hasError = false;
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.currentCategoryId) {
            this.$alert("请重新选择正确的品种");
            this.hasError = true;
            this.dialogFormVisible = true;
            return;
          } else if (!this.currentCommodityId) {
            this.$alert("请重新选择正确的品名");
            this.hasError = true;
            this.dialogFormVisible = true;
            return;
          } else if (!this.currentWarehouseId) {
            this.$alert("请重新选择正确的交货仓库");
            this.hasError = true;
            this.dialogFormVisible = true;
            return;
          }
          if (!tag) {
            this.dialogFormVisible = false;
          }
          const {
            categoryName,
            commodityName,
            productorName,
            warehouseName,
            weight,
            price
          } = this.itemForm;
          if (!this.itemForm.id) {
            this.tableData.unshift({
              id: Date.now(),
              categoryId: this.currentCategoryId,
              categoryName: categoryName,
              commodityId: this.currentCommodityId,
              commodityName: commodityName,
              productorId: this.currentProductorId,
              productorName: productorName,
              warehouseId: this.currentWarehouseId,
              warehouseName: warehouseName,
              weight: weight,
              weightUnit: this.unit,
              price: price,
              priceUnit: `元/${this.unit}`,
              amountOfMoney: this.amountOfMoney
            });
            //获取合同模板
            this.getContractTemplate();
          } else {
            this.tableData.forEach(item => {
              if (item.id == this.itemForm.id) {
                item.categoryId = this.currentCategoryId;
                item.categoryName = categoryName;
                item.commodityId = this.currentCommodityId;
                item.commodityName = commodityName;
                item.productorId = this.currentProductorId;
                item.productorName = productorName;
                item.warehouseId = this.currentWarehouseId;
                item.warehouseName = warehouseName;
                item.weight = weight;
                item.weightUnit = this.unit;
                item.price = price;
                item.priceUnit = `元/${this.unit}`;
                item.amountOfMoney = this.amountOfMoney;
              }
            });
            console.log(this.tableData);
            //获取合同模板
            this.getContractTemplate();
          }
          let sum = 0;
          this.tableData.forEach(item => {
            if (item.id) {
              sum += +item.amountOfMoney;
            }
          });
          this.totalAmount = sum;
          this.tableData.forEach(item => {
            if (item.categoryName == "合计") {
              item.amountOfMoney = sum;
            }
          });
        } else {
          this.hasError = true;
        }
      });
    },
    goonAdd(formName) {
      this.isEdit = false;
      this.submitItems(formName, "goonAdd");
      if (!this.hasError) {
        Object.keys(this.itemForm).forEach(item => {
          this.itemForm[item] = "";
        });
        // (this.currentCategoryId = ""),
        //   (this.currentCommodityId = ""),
        //   (this.currentProductorId = ""),
        //   (this.currentWarehouseId = "");
        this.$refs.itemForm.resetFields();
      }
    },
    updateItem(data) {
      this.isEdit = true;
      this.dialogFormVisible = true;
      let tempData = JSON.parse(JSON.stringify(data));
      this.itemForm = tempData;

      this.currentCategoryId = tempData.categoryId;
      this.currentCommodityId = tempData.commodityId;
      this.currentProductorId = tempData.productorId;
      this.currentWarehouseId = tempData.warehouseId;
    },
    deleteItem(index, data) {
      data.splice(index, 1);
      let sum = 0;
      this.tableData.forEach(item => {
        if (item.id) {
          sum += +item.amountOfMoney;
        }
      });
      this.tableData.forEach(item => {
        if (item.categoryName == "合计") {
          item.amountOfMoney = sum;
        }
      });
      //获取合同模板
      this.getContractTemplate();
    },

    //form表单搜索
    showCategory() {
      this.showCategoryContainer = true;
      this.showCategorylist = false;
    },
    showCommodity() {
      this.showCommodityContainer = true;
      this.showCommoditylist = false;
    },
    showProductor() {
      this.showProductorContainer = true;
      this.showProductorlist = false;
    },
    showWarehouse() {
      this.showWarehouseContainer = true;
      this.showWarehouselist = false;
    },
    hoverCategory(data) {
      this.cateContent = data;
    },
    hoverCommodity(data) {
      this.comContent = data;
    },
    hoverProductor(data) {
      this.proContent = data;
    },
    hoverWarehouse(data) {
      this.wareContent = data;
    },
    showAllCategory() {
      this.cateContent = this.allCategory;
    },
    showAllCommodity() {
      this.comContent = this.allCommodity;
    },
    showAllProductor() {
      this.proContent = this.allProductor;
    },
    showAllWarehouse() {
      this.wareContent = this.allWarehouse;
    },
    searchCategory() {
      this.showCategorylist = true;
      this.showCategoryContainer = false;
      let queryString = this.itemForm.categoryName;
      this.categorySearchResult = queryString
        ? this.allCategory.filter(
          this.createStateFilter(queryString, "category")
        )
        : this.allCategory;
    },
    searchCommodity() {
      this.showCommoditylist = true;
      this.showCommodityContainer = false;
      let queryString = this.itemForm.commodityName;
      this.commoditySearchResult = queryString
        ? this.allCommodity.filter(
          this.createStateFilter(queryString, "commodityName")
        )
        : this.allCommodity;
    },
    searchProductor() {
      this.showProductorlist = true;
      this.showProductorContainer = false;
      let queryString = this.itemForm.productorName;
      this.productorSearchResult = queryString
        ? this.allProductor.filter(
          this.createStateFilter(queryString, "producer")
        )
        : this.allProductor;
    },
    searchWarehouse() {
      this.showWarehouselist = true;
      this.showWarehouseContainer = false;
      let queryString = this.itemForm.warehouseName;
      this.warehouseSearchResult = queryString
        ? this.allWarehouse.filter(
          this.createStateFilter(queryString, "warehouse")
        )
        : this.allWarehouse;
    },
    hideContainer(event) {
      let category = document.getElementById('category');
      let commodity = document.getElementById('commodity');
      let productor = document.getElementById('productor');
      let warehouse = document.getElementById('warehouse');

      let cateFooter = document.getElementById('cateFooter');
      let comFooter = document.getElementById('comFooter');
      let proFooter = document.getElementById('proFooter');
      let wareFooter = document.getElementById('wareFooter');

      if (category) {
        if (!category.contains(event.target)) {
          if (cateFooter && cateFooter.contains(event.target)) {
            return;
          } else {
            this.showCategoryContainer = false;
            if (this.showCategorylist) {
              this.showCategorylist = false;
              this.itemForm.categoryName = '';
            }
          }
        }
      }
      if (commodity) {
        if (!commodity.contains(event.target)) {
          if (comFooter && comFooter.contains(event.target)) {
            return;
          } else {
            this.showCommodityContainer = false;
            if (this.showCommoditylist) {
              this.showCommoditylist = false;
              this.itemForm.commodityName = '';
            }
          }
        }
      }
      if (productor) {
        if (!productor.contains(event.target)) {
          if (proFooter && proFooter.contains(event.target)) {
            return;
          } else {
            this.showProductorContainer = false;
            if (this.showProductorlist) {
              this.showProductorlist = false;
              // this.itemForm.productorName = '';
            }
          }
        }
      }
      if (warehouse) {
        if (!warehouse.contains(event.target)) {
          if (wareFooter && wareFooter.contains(event.target)) {
            return;
          } else {
            this.showWarehouseContainer = false;
            if (this.showWarehouselist) {
              this.showWarehouselist = false;
              this.itemForm.warehouseName = '';
            }
          }
        }
      }
    },

    //品类搜索
    querySearchSCategory(tag) {
      let params = {
        process_id: "00000213",
        nextProcessNode: "002",
        addr: this.url.categorySearchUrl.addr, //获取蕉农合同信息列表 接口返回的url.categorySearchUrl.addr 的值
        nodeInfo: {
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0, //分页页码（从0开始）
          returnValueType: tag
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params).then(res => {
        if (res.succeed == 0) {
          const { data } = res;
          if (tag) {
            this.categoryContent = data.content;
          } else {
            this.allCategory = data.content;
            this.cateContent = this.allCategory;
          }
        }
      });
    },
    //品名搜索
    querySearchSCategoryName(tag) {
      let params = {
        process_id: "00000213",
        nextProcessNode: "002",
        addr: this.url.commoditySearchUrl.addr, //获取蕉农合同信息列表 接口返回的url.commoditySearchUrl.addr 的值
        nodeInfo: {
          categoryId: this.currentCategoryId, // 品种id
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0, //分页页码（从0开始）
          returnValueType: tag
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params).then(res => {
        if (res.succeed == 0) {
          const { data } = res;
          if (tag) {
            this.commodityContent = data.content;
          } else {
            this.allCommodity = data.content;
            this.comContent = this.allCommodity;
          }
        }
      });
    },
    //品牌搜索
    querySearchSProducer(tag) {
      let params = {
        process_id: "00000213",
        nextProcessNode: "002",
        addr: this.url.productorSearchUrl.addr, //获取蕉农合同信息列表 接口返回的url.commoditySearchUrl.addr 的值
        nodeInfo: {
          commodityId: this.currentCommodityId, // 品种id
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0, //分页页码（从0开始）
          returnValueType: tag
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params).then(res => {
        if (res.succeed == 0) {
          const { data } = res;
          if (tag) {
            this.productorContent = data.content;
          } else {
            this.allProductor = data.content;
            this.proContent = this.allProductor;
          }
        }
      });
    },
    //交货仓库搜索
    querySearchSWarehouse(tag) {
      let params = {
        process_id: "00000213",
        nextProcessNode: "002",
        addr: this.url.warehouseSearchUrl.addr, //获取蕉农合同信息列表 接口返回的url.warehouseSearchUrl.addr 的值
        nodeInfo: {
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0, //分页页码（从0开始）
          returnValueType: tag
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params).then(res => {
        if (res.succeed == 0) {
          const { data } = res;
          if (tag) {
            this.warehouseContent = data.content;
          } else {
            this.allWarehouse = data.content;
            this.wareContent = this.allWarehouse;
          }
        }
      });
    },
    //卖方公司名搜索
    querySearchSellerCompany(queryString, cb) {
      let params = {
        process_id: "00000213",
        execution_type: "get", //get 代表读请求 获取数据,  set	代表写请求 编辑等操作
        nextProcessNode: this.searchBtnId, // 上面接口的  button.NodeId_1字段的值
        nodeInfo: {
          companyName: this.sellerCompanyName.split("【")[0], //公司名称
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0 //分页页码（从0开始）
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then(res => {
          const { data } = res;
          this.options = data.content;
        })
        .catch(err => { });
    },
    createStateFilter(queryString, tag) {
      if (tag == "category") {
        return state => {
          return (
            state.categoryName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          );
        };
      } else if (tag == "commodityName") {
        return state => {
          return (
            state.commodityName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          );
        };
      } else if (tag == "producer") {
        return state => {
          return (
            state.productorName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          );
        };
      } else if (tag == "warehouse") {
        return state => {
          return (
            state.warehouseName
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          );
        };
      }
    },
    handleSelectSCategory(item) {
      this.currentCategoryId = item.categoryId;
      this.itemForm.categoryName = item.categoryName;
      setTimeout(() => {
        this.itemForm.categoryName = item.categoryName;
      }, 0);
      this.itemForm.commodityName = "";
      this.itemForm.productorName = "";
      this.querySearchSCategoryName();
      this.querySearchSCategoryName("classifiedByPinyin");
    },
    handleSelectCategoryName(item) {
      this.currentCommodityId = item.commodityId;
      this.itemForm.commodityName = item.commodityName;
      setTimeout(() => {
        this.itemForm.commodityName = item.commodityName;
      }, 0);
      this.unit = item.weightUnit;
      this.itemForm.productorName = '';
      this.querySearchSProducer();
      this.querySearchSProducer("classifiedByPinyin");
    },
    handleSelectProducer(item) {
      this.currentProductorId = item.productorId;
      this.itemForm.productorName = item.productorName;
      setTimeout(() => {
        this.itemForm.productorName = item.productorName;
      }, 0);
    },
    handleSelectWarehouse(item) {
      this.currentWarehouseId = item.warehouseId;
      this.itemForm.warehouseName = item.warehouseName;
      setTimeout(() => {
        this.itemForm.warehouseName = item.warehouseName;
      }, 0);
    },
    handleSelectBuyerCompany(item) {
      let users = item.users;
      this.sellerCompanyName = `${item.companyName}【${users[users.length - 1].name
        }】`;
      this.sellerCompanyNameTemp = `${item.companyName}`;
      this.searchCompanies();
    },
    getContractTemplate() {
      this.isShowTip = false;
      let categoryId = [],
        commodityId = [];
      this.templateNameList = [];
      this.tableData.forEach(item => {
        if (item.productorName) {
          categoryId.push(item.categoryId);
          commodityId.push(item.commodityId);
        }
      });
      let params = {
        process_id: "00000213",
        nextProcessNode: "004",
        addr: "listTemplates", //this.url.contractTemplateSearchUrl.addr, //获取列表 接口返回的url.contractTemplateSearchUrl.addr 的值
        nodeInfo: {
          categoryId: categoryId, // 品种id
          commodityId: commodityId, //品名id
          size: -1, //分页页容量（从0开始，-1代表查全部）
          page: 0 //分页页码（从0开始）
        }
      };
      this.$post("a6c611cc-ded1-4a49-8823-0c66f6ebf7d9", params)
        .then(res => {
          const { data } = res;
          if (data.content.length == 0) {
            this.isShowTip = true;
          }
          this.contractTemplateId = '';
          data.content.forEach(item => {
            this.templateNameList.push({
              contractTemplateId: item.templateId,
              contractTemplateName: item.templateName
            });
          });
        })
        .catch(err => { });
    },
    checkNum() {
      this.contractNumber = this.contractNumber.replace(/[^\w]/g, '')
    },
    gotoContractTemp(templateId) {
      this.$router.push({
        path: "/contractTempDetail",
        query: { id: templateId, 'process_id': '00000213' }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}
.form {
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  .hr {
    border-bottom: 1px solid #e6e6e6;
    height: 0;
    margin-bottom: 10px;
    margin-top: 13px;
  }
}
.block,
.block-1 {
  display: flex;
  align-items: center;
  .label {
    width: 100px;
    height: 45px;
    line-height: 45px;
    font-weight: bold;
    color: #333;
  }
  .content {
    color: #888;
  }
  .content-block {
    display: flex;
    align-items: center;
    .radio-item {
      vertical-align: bottom;
      margin-left: 2px;
      cursor: pointer;
      &:hover {
        color: orange;
      }
    }
    .content-block__label {
      width: 85px;
      text-align: right;
    }
    .content-block__content {
      display: flex;
      align-items: center;
      .btn {
        background: #2d5171;
        padding: 6px 32px;
        color: #ffffff;
        font-size: 12px;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
  .add-item-btn {
    background: #2d5171;
    padding: 6px 32px;
    color: #ffffff;
    font-size: 12px;
    cursor: pointer;
  }
}
.block-1 {
  display: block;
  margin-left: 100px;
  position: relative;
}
.send-btn {
  background: #c3214a;
  margin-left: 180px;
  width: 102px;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  margin-top: 50px;
}
.add-dialog-footer {
  display: flex;
  text-align: center;
  margin-left: 100px;
  .btn {
    width: 100px;
    height: 30px;
    line-height: 30px;
    background: #c3214a;
    border-radius: 0.25em;
    font-weight: bold;
    color: #ffffff;
    margin-right: 10px;
    cursor: pointer;
    &.cancel,
    &.confirm {
      background: #2d5171;
    }
  }
}
.container {
  position: absolute;
  z-index: 99;
  width: 480px;
  border: 1px solid rgb(47, 116, 149);
  background: #fff;
  .head {
    background: #67a1e2;
    color: #fff;
    font-size: 9pt;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }
  .content {
    height: 180px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .content-item {
    width: 100px;
    margin-left: 10px;
    height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }
  .footer {
    display: flex;
    height: 24px;
    background: rgb(200, 227, 252);
    .common {
      width: 40px;
      &:hover {
        background: rgb(232, 244, 255);
        cursor: pointer;
      }
    }
    .key-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .key {
        width: 100%;
        text-align: center;
        &:hover {
          background: rgb(232, 244, 255);
          cursor: pointer;
          text-align: center;
        }
      }
    }
    div {
      line-height: 24px;
    }
  }
}
.list {
  position: absolute;
  z-index: 9999;
  width: 180px;
  border: 1px solid #2f7495;
  background: #fff;
  height: 145px;
  padding: 10px 0;
  box-sizing: border-box;
  overflow: auto;
  li {
    color: #21407d;
    padding-left: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      background: #c8e3fc;
    }
  }
}
</style>
